<script>
export default {
  name: 'historique-index',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
  }
}
</script>

<template lang='pug'>
  .bibliotheque-index
    .page-header(tile, height='300', color='primary')
      .header__inner-content
        .header-title Historique des mes achats
    h1 À VENIR
</template>
<style lang='sass'>
  @import 'src/styles/pages/_bibliotheque'

</style>
